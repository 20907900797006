@import '../../styles/variables.scss';

#QRContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-size: auto 100%;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    gap: 10px;

    @media screen and (max-width: 768px) {
        padding-top: 100px;
    }

    .main-qr-wrapper-container {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 24px;

      @media screen and (max-width: 1100px) {
        flex-direction: column;
      }

      .qr-wrapper-side {
        font-size: 48px;
        font-weight: bold;
        color: #F3E995;
        text-transform: uppercase;
        font-family: "Bowlby One SC", sans-serif;
        min-width: 400px;
        
        @media screen and (max-width: 1100px) {
          font-size: 40px;

        }

        &.right {
          text-align: left;

          @media screen and (max-width: 1100px) {
            text-align: center;
          }
        }
      }
    .qr-wrapper {
        background-color: $primary-color;
        width: fit-content;
        padding: 44px;
        border-radius: 40px;
        box-shadow: 0px 0px 30px 0px rgba(229, 150, 49, 1);
        border: 2px solid $border-color;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        @media screen and (max-width: 760px) {
          padding: 10px;
        }
    }
  }


  .social-icons {
    display: none;
    padding: 4px;
    gap: 10px;
    font-family: "Bowlby One SC", sans-serif;

    @media screen and (max-width: 768px) {
        display: flex;
    }

    .social-icon {
      border-radius: 10px;
      text-decoration: none;
      color: $text-color;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      background: $button-background-color;
      padding: 8px 24px;
      border: 1px solid $border-color;

      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      
      &:hover {
        color: $hover-color;
        background: $gradient-golden-light;
      }
    }
  }
}