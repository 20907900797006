@import '../../styles//variables.scss';

.mobile-menu {
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 24px;
  padding-top: 48px;
  background: $primary-color;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  transform: translateX(-100%);
  transition: transform 0.3s ease-in-out;
  font-size: 48px;

  .close { 
    position: absolute;
    right: 20px;
    top: 0px;
 }

  a {
    color: $text-color;
    text-decoration: none;

    &.active::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: -5px; // Adjust this value based on your design
      width: 8px;
      height: 8px;
      background-color: $text-color;
      border-radius: 50%;
    }

    &.disabled {
      cursor: not-allowed;
      color: #aa714d7a;
      
      &:hover {
        text-decoration: none;
      }

      &::after {
        content: 'Coming Soon';
        position: absolute;
        background: #853300;
        color: #F3E995;
        font-size: 16px;
        padding: 2px 4px;
        border-radius: 4px;
        left: 50%;
        transform: translate(-50%, 200%);
        z-index: 1;
        text-wrap: nowrap;
      }
    }

    .mobile-social-icons {
      display: flex;
      padding: 4px;
      gap: 16px;
      width: 100%;
      justify-items: center;
      
  
      .mobile-social-icon {

        cursor: pointer;
  
        &.disabled {
          cursor: not-allowed;
  
          &::after {
            content: 'Coming Soon';
            position: absolute;
            background: $button-background-color;
            color: $text-color;
            font-size: 12px;
            padding: 4px;
            border-radius: 4px;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 1;
          }
        }
        
        &:hover {
          color: $hover-color;
          // background: $gradient-golden-light;
        }
      }
    }  
  
  }
  
  &.open {
    transform: translateX(0);
  }

  
  
}