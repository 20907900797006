@import '../styles/variables.scss';

#AboutScreen {
    width: 100%;
    min-height: 100vh;
    background-image: url('../assets/main_image_2.webp');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-color: black;
    font-family: "Bowlby One SC", sans-serif;

    h2 {
        color: #F3E995;
        font-size: 36px;
        font-weight: bold;
        text-align: center;
        text-transform: uppercase;
        max-width: 830px;
        margin-top: -20px;
    }

    .about-content {
        padding-top: 200px;
        padding-bottom: 96px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;
        justify-content: center;

        @media screen and (max-width: 768px) {
            padding-top: 100px;
            padding-left: 20px;
            padding-right: 20px;
        }

        .roadmap-image {
            @media screen and (max-width: 768px) {
                width: 100%;
            }
        }

        .about-header {
            color: #F3E995;
            font-size: 48px;
            font-weight: bold;
            text-align: left;
            margin-bottom: 20px;
            text-transform: uppercase;
            max-width: 770px;
            text-align: center;
        }

        p, ul {
            color: white;
            max-width: 770px;
            text-align: center;
            font-size: 20px;
            font-weight: 400;
        }

        ul {
            text-align: left !important;

            li span {
                position: relative;

                &.comming-soon::after {
                    position: absolute;
                    content: 'Coming Soon';
                    background: #853300;
                    color: #F3E995;
                    font-size: 10px;
                    padding: 2px 4px;
                    border-radius: 4px;
                    transform: translate(-50%, 50%);
                    text-wrap: nowrap;
                    margin-top: -4px;
                    right: -130px;
                  }
            }
        }
    }

    .images-wrapper {
        display: flex;
        justify-content: center;
        gap: 20px;
        margin-top: 20px;
        align-items: center;
        flex-wrap: wrap;

        img {
            width: 100%;
            max-width: 200px;
            border-radius: 10px;
        }
    }
}


