#Footer {
    display: flex;
    justify-content: space-between;
    padding: 24px;
    width: 100%;
    position: fixed;
    bottom: 0;
    box-sizing: border-box;
    font-family: "Bowlby One SC", sans-serif;
    font-size: 14px;

    @media screen and (max-width: 768px) {
        position: relative;
    }
    
    .footer-content {
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #F3E995;
        width: 100%;

        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 16px;
        }

        .footer-section {
            display: flex;
            align-items: center;
            gap: 20px;

            &-item {
                display: flex;
                align-items: center;
                gap: 10px;
            }
            
            a {
                color: #F3E995;
                text-decoration: none;

                &:hover {
                    text-decoration: underline;
                }
            }
        }
    }
}