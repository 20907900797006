@import '../../styles/variables.scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background: $primary-color;
  padding: 10px 0px;
  position: fixed;
  border-bottom: 2px solid $border-color;
  top: 0;
  width: 100%;
  height: 80px;
  box-shadow: 0px 0px 30px 0px $shadow-color;
  font-family: "Bowlby One SC", sans-serif;
  z-index: 2;

  .logo {
    font-size: 24px;
    font-weight: bold;
    color: $text-color-3;
    width: 350px;
    cursor: pointer;
    
    @media screen and (max-width: 1100px) {
      width: fit-content;
    }
  }

  .navbar {
    display: flex;
  }

  .nav-item {
    margin: 0 10px;
    text-decoration: none;
    color: $text-color-3;
    font-size: 18px;
    width: 350px;

    @media screen and (max-width: 1100px) {
      width: fit-content;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  .social-icons {
    display: flex;
    padding: 4px;
    gap: 16px;
    min-width: 350px;

    @media screen and (max-width: 1100px) {
      display: none;
    }

    .social-icon {
      display: flex;
      gap: 10px;
      border-radius: 10px;
      text-decoration: none;
      color: $text-color;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      // background: $button-background-color;
      // padding: 8px 24px;
      // border: 1px solid $border-color;

      &.disabled {
        cursor: not-allowed;

        &::after {
          content: 'Coming Soon';
          position: absolute;
          background: $button-background-color;
          color: $text-color;
          font-size: 12px;
          padding: 4px;
          border-radius: 4px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          z-index: 1;
        }
      }
      
      &:hover {
        color: $hover-color;
        // background: $gradient-golden-light;
      }
    }
  }

  .menu {
    display: flex;
    gap: 24px;
    
    @media screen and (max-width: 1100px) {
      display: none;
    }

    .menu-item {
      text-decoration: none;
      color: $text-color-3;
      font-size: 18px;
      position: relative; // Add this to enable positioning of the dot below
      text-wrap: nowrap;

      &:hover {
        text-decoration: underline;
      }

      &.active {
        color: $text-color-2;
      }

      &.active::after {
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: -5px; // Adjust this value based on your design
        width: 8px;
        height: 8px;
        background-color: $text-color;
        border-radius: 50%;
      }


      &.disabled {
        cursor: not-allowed;
        color: #aa714d7a;
        
        &:hover {
          text-decoration: none;
        }

        &::after {
          content: 'Coming Soon';
          position: absolute;
          background: #853300;
          color: #F3E995;
          font-size: 10px;
          padding: 2px 4px;
          border-radius: 4px;
          top: 50%;
          left: 50%;
          transform: translate(-50%, 50%);
          z-index: 1;
          text-wrap: nowrap;
        }
      }
    }
  }

  .menu-mobile {
    display: none;
    cursor: pointer;
    font-size: 24px;
    color: $text-color;

    @media screen and (max-width: 1100px) {
      display: block;
    }
  }
}
