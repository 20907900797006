$primary-color: rgba(250, 234, 214, 1);
$text-color: #8C6239;
$text-color-2: rgba(145, 71, 25, 1);
$text-color-3: #AA704D;
$hover-color: #B29559;
$gradient-golden: linear-gradient(180deg, #FFDCA6 0%, #E7AE58 126.73%);
$gradient-golden-light: linear-gradient(0deg, #FFCD81 0%, #E7AD56 100%);
$shadow-color: rgba(229, 150, 49, 0.5);
$border-color: rgba(229, 150, 49, 1);
$button-text-color: rgba(133, 51, 0, 1);
$button-background-color: #F2CB98;
