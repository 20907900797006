@import "../../styles/variables.scss";

.qr-code-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
}

.qr-code-container {
  position: relative;
  display: inline-block;
}

.qr-code {
  filter: blur(8px); /* Apply the blur effect */
  opacity: 0.5; /* Adjust opacity if needed */
}

.coming-soon-badge {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #853300;
  color: #F3E995;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  font-family: "Bowlby One SC", sans-serif; /* Match the font style */
  text-wrap: nowrap;
}

.modal-content {
  font-family: "Bowlby One SC", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 28px;
  color: $border-color;
  max-width: 300px;
  margin-top: 20px;
  text-align: center;
  width: 100%;
}

.join-now-button {
    background: $gradient-golden;
    border: 1px solid $border-color;
    width: 100%;
    border-radius: 10px;
    padding: 10px 20px;
    color: $button-text-color;
    margin-top: 20px;
    cursor: pointer;
    transition: 0.3s;
    font-family: "Bowlby One SC", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 18px;
  
    &:hover {
      background: $gradient-golden-light;
    }
  }
